import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const TeamBubble = ({ profileImage, forename, surname, title, team }) => {

    let teamIcon

    switch (team) {
        case 'Operations':
            teamIcon = 
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-6 lg:w-6 ws:h-10 ws:w-10 text-slate-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
        break

        case 'CAD & Visualisation':
            teamIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-6 lg:w-6 ws:h-10 ws:w-10 text-slate-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
            </svg>
        break

        case 'Science':
            teamIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-5 lg:w-5 ws:h-10 ws:w-10 text-slate-600" fill="none" viewBox="0 0 172 172" stroke="currentColor">
                <path d="M0,172v-172h172v172z" fill="none" />
                <g fill="#4b5569">
                    <path d="M86,0c-8.26317,0 -16.47729,1.17701 -24.43945,3.51335c-3.79833,1.11083 -5.98193,5.10401 -4.87109,8.90234c1.10367,3.79833 5.10401,5.98226 8.90234,4.8571c6.65067,-1.94933 13.51387,-2.93945 20.4082,-2.93945c39.517,0 71.66667,32.14967 71.66667,71.66667c0,39.517 -32.14967,71.66667 -71.66667,71.66667c-39.517,0 -71.66667,-32.14967 -71.66667,-71.66667c0,-6.89433 0.99012,-13.76436 2.93945,-20.4222c1.11083,-3.79833 -1.05876,-7.77035 -4.8571,-8.88835c-3.784,-1.12517 -7.79151,1.07276 -8.90234,4.87109c-2.33633,7.96217 -3.51335,16.17629 -3.51335,24.43945c0,47.42183 38.57817,86 86,86c47.42183,0 86,-38.57817 86,-86c0,-47.42183 -38.57817,-86 -86,-86zM28.66667,7.16667c-11.87412,0 -21.5,9.62588 -21.5,21.5c0,11.87412 9.62588,21.5 21.5,21.5c11.87412,0 21.5,-9.62588 21.5,-21.5c0,-11.87412 -9.62588,-21.5 -21.5,-21.5zM86,35.83333c-27.66333,0 -50.16667,22.50333 -50.16667,50.16667c0,26.90367 21.01827,48.90018 47.8431,50.08268c0.1075,0 0.21444,0.014 0.32194,0.014c3.81267,0 6.98784,-3.01022 7.15267,-6.85872c0.172,-3.956 -2.88873,-7.30261 -6.84473,-7.47461c-19.14217,-0.8385 -34.13965,-16.54951 -34.13965,-35.76334c0,-19.7585 16.07483,-35.83333 35.83333,-35.83333c19.21383,0 34.92484,14.99748 35.76334,34.13965c0.172,3.956 3.51144,6.91639 7.47461,6.84473c3.956,-0.172 7.01673,-3.51861 6.84473,-7.47461c-1.1825,-26.82483 -23.17901,-47.8431 -50.08268,-47.8431zM86,71.66667c-7.91608,0 -14.33333,6.41725 -14.33333,14.33333c0,7.91608 6.41725,14.33333 14.33333,14.33333c7.91608,0 14.33333,-6.41725 14.33333,-14.33333c0,-7.91608 -6.41725,-14.33333 -14.33333,-14.33333zM118.25,100.33333c-9.8951,0 -17.91667,8.02156 -17.91667,17.91667c0,9.8951 8.02156,17.91667 17.91667,17.91667c9.8951,0 17.91667,-8.02156 17.91667,-17.91667c0,-9.8951 -8.02156,-17.91667 -17.91667,-17.91667z" />
                </g>
            </svg>
        break

        case 'Mechanical Systems':
            teamIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-6 lg:w-6 ws:h-10 ws:w-10 text-slate-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        break

        case 'Digital Systems':
            teamIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-6 lg:w-6 ws:h-10 ws:w-10 text-slate-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
            </svg>
        break

        case 'Systems Engineering':
            teamIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-6 lg:w-6 ws:h-10 ws:w-10 text-slate-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
            </svg>
        break
    }

    const profileImageRender = getImage(profileImage)

    return (
        <div className='w-full flex flex-col items-center'>

            <div className='w-4/5 h-fit'>
                <div className='relative'>
                    <GatsbyImage className='col-start-1 row-start-1 w-full h-auto drop-shadow-lg rounded-full' image={profileImageRender} alt={forename + ' ' + surname}/>
                    <div className={'bottom-0 left-0 bg-slate-100 p-2 lg:p-3 ws:p-5 rounded-full drop-shadow-xl ' + (team ? 'absolute' : 'hidden')} title={team}>{teamIcon}</div>
                </div>
            </div>

            

            <h2 className='mt-5 font-semibold text-2xl text-center'>{forename} {surname}</h2>
            <div className='flex flex-row justify-center items-center'>
                <div title={team}></div>
                <h3 className='text-center'>{title}</h3>
            </div>

        </div>
    )
}

export default TeamBubble