import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TeamBubble from '../components/TeamBubble'
import team_banner from '../images/team_banner.jpg'
import blue_origin from '../images/company-logos/blue-origin.svg'
import nasa_worm from '../images/company-logos/nasa-worm.svg'
import spacex from '../images/company-logos/spacex.svg'
import skyroot from '../images/company-logos/skyroot.svg'
import smc from '../images/company-logos/smc.svg'
import orbex from '../images/company-logos/orbex.svg'
import textron from '../images/company-logos/textron.svg'
import erau from '../images/company-logos/ERAU.svg'
import ucl from '../images/company-logos/ucl.svg'
import tum from '../images/company-logos/tum.png'
import wien from '../images/company-logos/wien.svg'
import florida_tech from '../images/company-logos/florida-tech.svg'


const TeamPage = ({data}) => {
    return (
      <Layout title="Team | Conex Research">
        <section className="w-screen h-fit grid grid-cols-1 grid-rows-1 items-center">
          <img
            className="col-start-1 row-start-1 h-full sm:w-full object-cover object-bottom brightness-50"
            src={team_banner}
          />
          <div className="col-start-1 row-start-1 px-8 xl:px-32 mt-20">
            <h1 className="text-5xl sm:text-7xl ws:text-9xl font-bold text-white drop-shadow-lg">
              Our Team
            </h1>
            <h2 className="mb-20 text-xl sm:text-3xl ws:text-5xl text-white drop-shadow-lg">
              Meet the people behind the research
            </h2>
          </div>
        </section>

        <section className="w-screen h-fit px-8 xl:px-32 mt-20">
          <div className="grid grid-cols-2 sm:grid-cols-5 2xl:grid-cols-6 ws:grid-cols-8 gap-x-3 sm:gap-x-10 gap-y-20">
            <>
              {data.allContentfulTeamMember.nodes.map((member) => (
                <TeamBubble
                  profileImage={member.profileImage}
                  forename={member.forename}
                  surname={member.surname}
                  title={member.title}
                  team={member.team}
                />
              ))}
            </>
          </div>
        </section>

        <div>
          <h1 className="mt-28 px-7 text-4xl sm:text-6xl ws:text-7xl text-center font-extrabold text-conex-blue dark:text-blue-600">
            A team with experience at
          </h1>

          <div className="grid py-10">
            <div className="bg-gradient-to-t from-white w-screen col-start-1 row-start-1 z-10 opacity-70" />

            <div className="col-start-1 row-start-1 w-4/5 mx-auto grid grid-cols-2 sm:grid-cols-3 sm:grid-rows-4 gap-x-5 sm:gap-x-20 gap-y-5 sm:gap-y-0 items-center justify-items-center">
              <img src={blue_origin} />
              <img className="w-3/5" src={nasa_worm} />
              <img src={spacex} />

              <img src={skyroot} />
              <img className="w-4/5" src={smc} />
              <img className="w-1/2" src={orbex} />

              <img className="w-4/5" src={textron} />
              <img src={erau} />
              <img className="w-2/3" src={ucl} />

              <img className="w-5/6" src={tum} />
              <img className="w-5/6" src={wien} />
              <img className="w-4/5" src={florida_tech} />
            </div>
          </div>
        </div>

        <div className="w-screen h-fit flex justify-center items-center mb-20 px-10">
          <div className="w-fit h-fit flex flex-col justify-center items-center mt-20 px-10 py-7 rounded-lg shadow-xl bg-shell dark:bg-neutral-800">
            <h3 className="font-bold text-3xl text-center mb-3">
              Interested in joining our team?
            </h3>
            <a
              key={"apply"}
              href={"/join"}
              target="_blank"
              className={
                "flex flex-row items-center text-xl font-semibold text-white hover:underline mt-3 px-5 py-3 rounded-md bg-black"
              }
            >
              {"Join us >"}
            </a>
          </div>
        </div>
      </Layout>
    );
}

export const query = graphql`
  query {
    allContentfulTeamMember(sort: {fields: displayOrder, order: ASC}) {
        nodes {
            id,
            profileImage {
                gatsbyImageData
            },
            forename,
            surname,
            title,
            team,
        }
    }
  }
`;

export default TeamPage